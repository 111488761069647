import React, { useEffect } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';

import ToasterContainer from 'components/organisms/ToasterContainer';
import asyncComponent from 'components/templates/AsyncComponent';
import { permissionList, usePermission } from 'hooks/usePermission';

export const RestrictedRoute = ({
  component: Component,
  authUser,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/signin',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);
const Routes = ({ match }) => {
  const intl = useIntl();
  const { evaluate } = usePermission();

  const Page = ({ component: Component, title, ...rest }) => {
    useEffect(() => {
      document.title = title ? `Vpag Flash - ${title}` : 'Vpag Flash';
    }, [title]);

    return <Route {...rest} render={(props) => <Component {...props} />} />;
  };

  useEffect(() => {
    const tokenListener = window.addEventListener('invalid_token', () => {
      localStorage.setItem('show_expired_session', true);
      window.location.href = '/signin';
    });

    return () => tokenListener;
  }, []);

  return (
    <>
      <Switch>
        <Page
          exact
          path={`${match.url}/dashboard`}
          component={asyncComponent(() => import('./Dashboard'))}
          title={intl.formatMessage({ id: 'page.title.dashboard' })}
        />
        <Page
          exact
          path={`${match.url}/profile/:id`}
          component={asyncComponent(() => import('./ProfileSettings'))}
          title={intl.formatMessage({ id: 'page.title.profile' })}
        />
        <Page
          exact
          path={`${match.url}/users/customer`}
          component={asyncComponent(() =>
            evaluate([permissionList.customerRead])
              ? import('./Users/List/Customer')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.users' })}
        />
        <Page
          exact
          path={`${match.url}/users/administrator`}
          component={asyncComponent(() =>
            evaluate([permissionList.userRead])
              ? import('./Users/List/Admin')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.users' })}
        />
        <Page
          exact
          path={`${match.url}/users/administrator/:id`}
          component={asyncComponent(() =>
            evaluate([permissionList.userRead])
              ? import('./Users/Details/Admin')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.users' })}
        />
        <Page
          exact
          path={`${match.url}/users/merchant`}
          component={asyncComponent(() =>
            evaluate([permissionList.userMerchantRead])
              ? import('./Users/List/Merchant')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.users' })}
        />
        <Page
          exact
          path={`${match.url}/users/banks`}
          component={asyncComponent(() =>
            evaluate([
              permissionList.customerRead,
              permissionList.customerBankRead,
            ])
              ? import('./Users/CustomerBanks/List')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.banksByUser' })}
        />
        <Page
          exact
          path={`${match.url}/users/merchant/:id`}
          component={asyncComponent(() =>
            evaluate([permissionList.userMerchantRead])
              ? import('./Users/Details/Merchant')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.users' })}
        />
        <Page
          exact
          path={`${match.url}/users/customer/:id`}
          component={asyncComponent(() =>
            evaluate([permissionList.customerRead])
              ? import('./Users/Details/Customer')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.customerDetails' })}
        />
        <Page
          path={`${match.url}/create/user/administrator`}
          component={asyncComponent(() =>
            evaluate([permissionList.userCreate])
              ? import('./Users/Create/AdminUser')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.createUser' })}
        />
        <Page
          path={`${match.url}/create/user/merchant`}
          component={asyncComponent(() =>
            evaluate([permissionList.userMerchantCreate])
              ? import('./Users/Create/MerchantUser')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.createUser' })}
        />
        <Page
          exact
          path={`${match.url}/create/merchant`}
          component={asyncComponent(() =>
            evaluate([permissionList.merchantCreate])
              ? import('./Merchants/Create')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.createMerchant' })}
        />
        <Page
          exact
          path={`${match.url}/merchants`}
          component={asyncComponent(() =>
            evaluate([permissionList.menuMerchants])
              ? import('./Merchants/List')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.merchants' })}
        />
        <Page
          exact
          path={`${match.url}/merchants/:id`}
          component={asyncComponent(() =>
            evaluate([permissionList.merchantRead])
              ? import('./Merchants/Details')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.merchantDetails' })}
        />
        <Page
          exact
          path={`${match.url}/banks`}
          component={asyncComponent(() =>
            evaluate([permissionList.menuBanks])
              ? import('./Banks/List')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.banks' })}
        />
        <Page
          exact
          path={`${match.url}/banks/:id`}
          component={asyncComponent(() =>
            evaluate([permissionList.bankRead])
              ? import('./Banks/Details')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.banks' })}
        />
        <Page
          exact
          path={`${match.url}/create/bank`}
          component={asyncComponent(() =>
            evaluate([permissionList.bankCreate])
              ? import('./Banks/Create')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.banks' })}
        />
        <Page
          exact
          path={`${match.url}/transactions`}
          component={asyncComponent(() =>
            evaluate([permissionList.transactionRead])
              ? import('./Transactions/List')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.transactions' })}
        />
        <Page
          exact
          path={`${match.url}/pix-and-go-issues`}
          component={asyncComponent(() =>
            evaluate([permissionList.pixAndGoRead])
              ? import('./PixAndGo/Transactions/List')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.pix-and-go-issues' })}
        />
        <Page
          exact
          path={`${match.url}/pix-and-go-issues/:id`}
          component={asyncComponent(() =>
            evaluate([permissionList.pixAndGoRead])
              ? import('./PixAndGo/Transactions/Details')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.pix-and-go-issues' })}
        />
        <Page
          exact
          path={`${match.url}/issues`}
          component={asyncComponent(() =>
            evaluate([permissionList.transactionIssueRead])
              ? import('./Issues')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.issues' })}
        />
        <Page
          exact
          path={`${match.url}/fitbank-transfer`}
          component={asyncComponent(() =>
            evaluate([permissionList.transactionRead])
              ? import('./FitBankTransfer')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.fitbankTransfers' })}
        />
        <Page
          exact
          path={`${match.url}/transactions/:id`}
          component={asyncComponent(() =>
            evaluate([permissionList.transactionRead])
              ? import('./Transactions/Details')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.transactionDetails' })}
        />
        <Page
          exact
          path={`${match.url}/reports`}
          component={asyncComponent(() =>
            evaluate([permissionList.acamReportRead])
              ? import('./Reports/AcamReport/List')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.reports' })}
        />
        <Page
          exact
          path={`${match.url}/reports/acam/:id`}
          component={asyncComponent(() =>
            evaluate([permissionList.acamReportRead])
              ? import('./Reports/AcamReport/Details')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.reportDetails' })}
        />
        <Page
          exact
          path={`${match.url}/create/report`}
          component={asyncComponent(() =>
            evaluate([permissionList.acamReportCreate])
              ? import('./Reports/AcamReport/Create')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.createReport' })}
        />
        <Page
          exact
          path={`${match.url}/approvals/transactions`}
          component={asyncComponent(() =>
            evaluate([
              permissionList.customerRead,
              permissionList.transactionReviewRead,
            ])
              ? import('./Approvals/PendingTransactions/List')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.pendingTransactions' })}
        />
        <Page
          exact
          path={`${match.url}/approvals/transactions/:id`}
          component={asyncComponent(() =>
            evaluate([permissionList.transactionReviewRead])
              ? import('./Approvals/PendingTransactions/Details')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.transactionApproval' })}
        />
        <Page
          exact
          path={`${match.url}/approvals/sof`}
          component={asyncComponent(() =>
            evaluate([permissionList.customerDocumentRead])
              ? import('./Approvals/Sof/List')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.sofApproval' })}
        />
        <Page
          exact
          path={`${match.url}/approvals/sof/:id`}
          component={asyncComponent(() =>
            evaluate([
              permissionList.customerRead,
              permissionList.customerDocumentRead,
            ])
              ? import('./Approvals/Sof/Details')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.sofApproval' })}
        />
        <Page
          exact
          path={`${match.url}/merchant-credentials`}
          component={asyncComponent(() =>
            evaluate([permissionList.oAuthClientRead])
              ? import('./MerchantCredentials')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.credentials' })}
        />
        <Page
          exact
          path={`${match.url}/balances`}
          component={asyncComponent(() =>
            evaluate([permissionList.menuBalances, permissionList.merchantRead]) ||
              evaluate([permissionList.bankRead])
              ? import('./Reports/Balances')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.reports' })}
        />
        <Page
          exact
          path={`${match.url}/daily-report`}
          component={asyncComponent(() =>
            evaluate([permissionList.summaryDailyTransactionsRead])
              ? import('./Reports/DailyTransactionReport')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.reports' })}
        />
        <Page
          exact
          path={`${match.url}/daily-report-by-bank`}
          component={asyncComponent(() =>
            evaluate([permissionList.summaryDailyTransactionsRead])
              ? import('./Reports/DailyTransactionByBankReport')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.reports' })}
        />
        <Page
          exact
          path={`${match.url}/monthly-report`}
          component={asyncComponent(() =>
            evaluate([permissionList.merchantMonthlyReportsRead])
              ? import('./Reports/MonthlyMerchantReport')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.monthlyMerchantReport' })}
        />
        <Page
          exact
          path={`${match.url}/customer-transactions`}
          component={asyncComponent(() =>
            evaluate([permissionList.summaryTransactionsCustomerRead])
              ? import('./Reports/CustomerTransactionSummary')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.reports' })}
        />
        <Page
          exact
          path={`${match.url}/notifications-report`}
          component={asyncComponent(() =>
            evaluate([permissionList.notificationRead])
              ? import('./Reports/NotificationsReport')
              : import('./RestrictedAccess')
          )}
          title={intl.formatMessage({ id: 'page.title.reports' })}
        />
        <Page
          exact
          path={`${match.url}/beta-bi-conciliation`}
          title={intl.formatMessage({ id: 'page.title.betaBi' })}
          component={asyncComponent(() =>
            evaluate([permissionList.superSetRead])
              ? import('./BetaBi')
              : import('./RestrictedAccess')
          )}
        />
      </Switch>
      <ToasterContainer />
    </>
  );
};

export default withRouter(Routes);
